var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"contentBox",staticClass:"box-faultr",attrs:{"id":"contentBox"}},[_c('div',{ref:"headerRef",staticClass:"header",attrs:{"id":"headerBox"}},[_c('div',{style:({ height: `${_vm.appBarHeight}px`, backgroundColor: 'white' })}),_c('div',{staticClass:"navBar"},[_c('img',{staticClass:"back",attrs:{"src":"https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png","alt":""},on:{"click":_vm.goback}}),_c('div',{staticClass:"title"},[_vm._v("工单详情")])])]),_c('div',{staticClass:"header-placehold",style:({
      height: `${_vm.headerH + _vm.appBarHeight}px`,
      backgroundColor: 'white',
    })}),_c('div',{staticClass:"top-vin"},[_c('div',{staticClass:"text"},[_vm._v(" 车辆 "),_c('span',{staticClass:"vin"},[_vm._v(_vm._s(_vm.detailObj.vinId))])]),_c('div',{staticClass:"location"},[_c('img',{attrs:{"src":require("@/assets/faultrReporting/location.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.detailObj.parkName))])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},_vm._l((Object.keys(_vm.detailHeader)),function(item,index){return _c('div',{key:index,staticClass:"box"},[(
          item !== 'mainFaultValue' &&
          item !== 'faultMoudle' &&
          item !== 'childfaultValue' &&
          item !== 'imageUrls' &&
          item !== 'faultPartDTOList' &&
          item !== 'compensationAmount' &&
          _vm.getBoolean(item)
        )?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},[_vm._v(_vm._s(_vm.detailObj[item]))])]):_vm._e(),(item === 'mainFaultValue' && _vm.getBoolean(item))?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},[_vm._v(_vm._s(_vm.detailObj[item]))])]):_vm._e(),(item === 'faultMoudle' && _vm.getBoolean(item))?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},[_vm._v(_vm._s(_vm.detailObj[item]))])]):_vm._e(),(item === 'childfaultValue' && _vm.getBoolean(item))?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},[_vm._v(_vm._s(_vm.detailObj[item]))])]):_vm._e(),(item === 'faultPartDTOList' && _vm.getBoolean(item) && _vm.detailObj.solutionType == 109002)?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},[_vm._v(_vm._s(_vm.getFaultPartDTOList(_vm.detailObj[item])))])]):_vm._e(),(
          item === 'compensationAmount' &&
          _vm.getBoolean(item) &&
          (_vm.detailObj.liabilityType == 108005 ||
            _vm.detailObj.liabilityType == 108007 ||
            _vm.detailObj.liabilityType == 108006)
        )?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},[_vm._v(_vm._s(_vm.detailObj[item])+" 元")])]):_vm._e(),(item === 'imageUrls' && _vm.getBoolean(item) && _vm.detailObj.imageUrls.length > 0)?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailHeader[item]))]),_c('p',{staticClass:"cont"},_vm._l((_vm.detailObj.imageUrls),function(item,index){return _c('span',{key:index,staticClass:"imageUrls"},[(_vm.isImage(item))?_c('van-image',{attrs:{"width":"86","height":"86","src":item},on:{"click":function($event){return _vm.handleImageClick($event, item)}}}):_c('span',{staticClass:"video-container",on:{"click":function($event){return _vm.playVideo(item)}}},[_c('video',{attrs:{"src":item,"width":"86","height":"86","poster":require("../../assets/detail/poster-url.png")}}),_c('van-icon',{attrs:{"name":"play-circle-o"}})],1)],1)}),0)]):_vm._e(),_c('van-dialog',{model:{value:(_vm.videoShow),callback:function ($$v) {_vm.videoShow=$$v},expression:"videoShow"}},[_c('video',{attrs:{"src":_vm.videoUrl,"width":"100%","height":"300","controls":"","preload":"","poster":require("../../assets/detail/poster-url.png"),"autoplay":"","controlslist":"nodownload disablePictureInPicture noplaybackrate"}})])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }