<template>
  <div class="box-faultr" id="contentBox" ref="contentBox">
    <div id="headerBox" ref="headerRef" class="header">
      <div :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"></div>
      <div class="navBar">
        <img
          class="back"
          @click="goback"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
        <div class="title">工单详情</div>
      </div>
    </div>
    <div
      class="header-placehold"
      :style="{
        height: `${headerH + appBarHeight}px`,
        backgroundColor: 'white',
      }"
    ></div>
    <!-- 车辆 -->
    <div class="top-vin">
      <div class="text">
        车辆 <span class="vin">{{ detailObj.vinId }}</span>
      </div>
      <div class="location">
        <img src="@/assets/faultrReporting/location.png" alt="" />
        <span>{{ detailObj.parkName }}</span>
      </div>
    </div>
    <div class="line" />
    <div class="content">
      <div class="box" v-for="(item, index) in Object.keys(detailHeader)" :key="index">
        <div
          v-if="
            item !== 'mainFaultValue' &&
            item !== 'faultMoudle' &&
            item !== 'childfaultValue' &&
            item !== 'imageUrls' &&
            item !== 'faultPartDTOList' &&
            item !== 'compensationAmount' &&
            getBoolean(item)
          "
        >
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">{{ detailObj[item] }}</p>
        </div>
        <!-- 主故障 -->
        <div v-if="item === 'mainFaultValue' && getBoolean(item)">
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">{{ detailObj[item] }}</p>
        </div>
        <!-- 故障模块 -->
        <div v-if="item === 'faultMoudle' && getBoolean(item)">
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">{{ detailObj[item] }}</p>
        </div>
        <!-- 子故障 -->
        <div v-if="item === 'childfaultValue' && getBoolean(item)">
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">{{ detailObj[item] }}</p>
        </div>
        <!-- 零部件种类和数量 -->
        <div
          v-if="item === 'faultPartDTOList' && getBoolean(item) && detailObj.solutionType == 109002"
        >
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">{{ getFaultPartDTOList(detailObj[item]) }}</p>
        </div>
        <!-- 事故赔偿金额 -->
        <div
          v-if="
            item === 'compensationAmount' &&
            getBoolean(item) &&
            (detailObj.liabilityType == 108005 ||
              detailObj.liabilityType == 108007 ||
              detailObj.liabilityType == 108006)
          "
        >
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">{{ detailObj[item] }} 元</p>
        </div>
        <!-- 图片列表 -->
        <div v-if="item === 'imageUrls' && getBoolean(item) && detailObj.imageUrls.length > 0">
          <p class="title">{{ detailHeader[item] }}</p>
          <p class="cont">
            <span class="imageUrls" v-for="(item, index) in detailObj.imageUrls" :key="index">
              <van-image
                v-if="isImage(item)"
                width="86"
                height="86"
                @click="handleImageClick($event, item)"
                :src="item"
              />
              <span class="video-container" @click="playVideo(item)" v-else>
                <video
                  :src="item"
                  width="86"
                  height="86"
                  poster="../../assets/detail/poster-url.png"
                />
                <van-icon name="play-circle-o" />
              </span>
            </span>
          </p>
        </div>
        <van-dialog v-model="videoShow">
          <video
            :src="videoUrl"
            width="100%"
            height="300"
            controls
            preload
            poster="../../assets/detail/poster-url.png"
            autoplay
            controlslist="nodownload disablePictureInPicture noplaybackrate"
          />
        </van-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
import { getFaultOrderDetail, getFaultDict } from '@/api/apiv2';
import { param2Obj } from '@/utils';

export default {
  components: {},
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
      headerH: 48,
      videoShow: false,
      detailObj: {},
      detailHeader: {
        faultTime: '故障时间',
        faultTypeValue: '故障类型',
        faultMoudle: '故障模块', //
        mainFaultValue: '主故障',
        childfaultValue: '子故障', //
        faultDesc: '具体描述',
        liabilityValue: '故障责任判定',
        solutionTypeValue: '处理方案',
        faultPartDTOList: '零部件种类和数量',
        compensationAmount: '事故赔偿金额',
        solutionDesc: '方案描述',
        imageUrls: '故障图片/视频',
      },
      videoUrl: '',
    };
  },
  computed: {},
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
    },
    playVideo(item) {
      this.videoUrl = item;
      setTimeout(() => {
        this.videoShow = true;
      }, 500);
    },
    // 返回
    async goback() {
      if (window.location.href.indexOf('//localhost:') > -1) {
        this.$router.go(-1);
        return;
      }
      window.location.href = 'neolix://more';
    },
    async getDetail(id) {
      const res = await getFaultOrderDetail({ id });
      this.detailObj = res;
    },
    async getFaultDicts() {
      const res = await getFaultDict({ typeCode: null });
      this.typeArr = res['40'].find((item) => item.dictType == 109002).childFaultDictVO;
      const query = param2Obj(location.href);
      if (query.id) {
        this.getDetail(query.id);
      }
    },
    getFaultPartDTOList(data) {
      let desArr = [];
      this.typeArr.map((item) => {
        data.map((item1) => {
          if (item.dictType == item1.partCategory) {
            if (item1.quantity && Number(item1.quantity) > 0) {
              desArr.push(`${item.dictTypeValue}*${item1.quantity}`);
            }
          }
        });
      });
      return desArr.length > 0 ? desArr.join(',') : null;
    },
    getBoolean(item) {
      let flag = false;
      if (
        this.detailObj[item] !== '' &&
        this.detailObj[item] !== null &&
        this.detailObj[item] !== undefined
      ) {
        flag = true;
      }
      return flag;
    },
    handleImageClick(event, item) {
      const images = [];
      this.detailObj.imageUrls.forEach((element) => {
        if (this.isImage(element)) {
          images.push(element);
        }
      });
      const choseIndex = images.findIndex((element) => element === item);
      ImagePreview({
        images,
        startPosition: choseIndex,
      });
    },
  },
  mounted() {
    this.getFaultDicts();
  },
};
</script>
<style scoped lang="scss">
#contentBox {
  height: 100%;
  overflow: scroll;
}

.box-faultr {
  width: 100%;
  // height: 100vh;
  font-size: initial;
  background: #fff;
  .header {
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: white;
    top: 0;
    .navBar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      line-height: 48px;
      position: relative;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;

      .back {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        // top: 0.07rem;
        left: 0.2rem;
        z-index: 3;
      }
      .title {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 48px;
    background: rgba(245, 246, 250, 1);
  }
  .top-vin {
    width: 100%;
    padding: 16px 20px;
    background: #ffffff;
    .text {
      font-size: 17px;
      color: #000000;
      margin-bottom: 6px;
      font-weight: 600;
      .vin {
        font-size: 20px;
      }
    }
    .location {
      margin-right: 2px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .line {
    width: 100%;
    min-height: 10px;
    background: rgba(246, 247, 248, 1);
  }
  .content {
    width: 100%;
    padding: 16px 20px;
    background: #ffffff;
    .box {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 19.6px;
        color: rgba(0, 0, 0, 1);
      }
      .cont {
        margin: 16px 0 24px 0;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 22px;
        color: rgba(0, 0, 0, 1);
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
}
::v-deep(.van-image) {
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
  img {
    object-fit: cover;
  }
}
.imageUrls {
  display: inline-block;
  height: 86px;
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
  &:nth-child(4) {
    margin-left: 0;
  }
}
.video-container {
  display: inline-block;
  position: relative;
  ::v-deep(.van-icon-play-circle-o) {
    position: absolute;
    top: calc(50% - 21px);
    left: calc(50% - 16px);
    font-size: 30px !important;
    color: #ffffff;
  }
  video {
    object-fit: fill; /*cover封面覆盖*/
  }
}
::v-deep(.van-dialog) {
  width: 95% !important;
  .van-dialog__content {
    font-family: none;
  }
  .van-dialog__confirm {
    text-align: center;
    font-size: 14px;
    color: rgba(67, 110, 255, 1);
  }
}
</style>
